import {
  Environment,
  Network,
  RecordSource,
  Store,
  Observable,
  Variables,
  CacheConfig,
  RequestParameters,
  GraphQLResponse,
  SubscribeFunction,
  DataID,
} from "relay-runtime"
import { RequestParams, createClient } from "graphql-sse"
import { getAccessToken } from "./store/authStore"
// import { isEmpty } from "lodash-es"
import RelayQueryResponseCache from "relay-runtime/lib/network/RelayQueryResponseCache"
import { Sink } from "relay-runtime/lib/network/RelayObservable"
import { MutableRecordSource, RecordMap, Record } from "relay-runtime/lib/store/RelayStoreTypes"

const DEV_HTTP_ENDPOINT = "http://localhost:3100/graphql"
// const HTTP_ENDPOINT = "https://tb3b3dfih34mmefasig2jfbp2q0sdgrm.lambda-url.us-east-1.on.aws/graphql"
const HTTP_ENDPOINT = "https://hlu4so7zpi4wu6fihfa43hzhye0tdiyn.lambda-url.ap-southeast-1.on.aws/graphql"

const DEV_WEBSOCKET_ENDPOINT = "http://localhost:3100/graphql"
const WEBSOCKET_ENDPOINT = "https://hlu4so7zpi4wu6fihfa43hzhye0tdiyn.lambda-url.ap-southeast-1.on.aws/graphql"

const isProd = import.meta.env.PROD

const oneHour = 60 * 60 * 1000
const cache = new RelayQueryResponseCache({ size: 250, ttl: oneHour })

const accessToken = getAccessToken()
const fetchFunction = async (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  sink: Sink<GraphQLResponse>,
) => {
  // const { clearTokens } = getAuthActions()

  const queryID = operation.text
  const isMutation = operation.operationKind === "mutation"
  const isQuery = operation.operationKind === "query"
  const forceFetch = cacheConfig && cacheConfig.force

  const fromCache = cache.get(queryID as string, variables)
  if (isQuery && fromCache !== null && !forceFetch) {
    // Return data from cache
    // Then continue
    sink.next(fromCache)
  }

  const res = await fetch(isProd ? HTTP_ENDPOINT : DEV_HTTP_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/graphql-response+json; charset=utf-8, application/json; charset=utf-8",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
      id: operation?.id,
    }),
  })

  const response = await res.json()

  // if (!isEmpty(response.errors)) {
  //   const hasUnauthorized = response.errors.some((err: { message: string }) => err.message === "Unauthorized")
  //   if (hasUnauthorized) {
  //     console.log("Logged out...")
  //     // window.location.replace("/")
  //     // clearTokens()
  //   }
  //   throw Error(JSON.stringify(response.errors, null, 4))
  // }

  // console.log("@response: ", response)

  if (isMutation && response.errors) {
    // sink.error(response)
    sink.next(response)
    sink.complete()

    return
  }

  // Set cache
  if (isQuery && response) {
    cache.set(queryID as string, variables, response)
  }
  // Clear cache on mutations
  if (isMutation) {
    cache.clear()
  }

  sink.next(response)
  sink.complete()
}

const executeFunction = (
  request: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  // uploadables?: UploadableMap | null,
) => {
  return Observable.create((sink) => {
    fetchFunction(request, variables, cacheConfig, sink)
  })
}

let subscribeFn: SubscribeFunction

interface ExtendedRequestParams extends RequestParams {
  id?: string | null
}

if (typeof window !== "undefined") {
  // We only want to setup subscriptions if we are on the client.
  const subscriptionsClient = createClient({
    url: isProd ? WEBSOCKET_ENDPOINT : DEV_WEBSOCKET_ENDPOINT,
  })

  subscribeFn = (request, variables) => {
    // To understand why we return Observable.create<any>,
    // please see: https://github.com/enisdenjo/graphql-ws/issues/316#issuecomment-1047605774
    return Observable.create<never>((sink) => {
      const extendedRequest: ExtendedRequestParams = {
        operationName: request.name,
        query: request.text ?? "",
        variables,
        id: request?.id, // Now 'id' is a valid property
      }

      return subscriptionsClient.subscribe(extendedRequest, sink)
    })
  }
}
function loadRelayStore(): RecordMap {
  const storedData = localStorage.getItem("RELAY_STORE_KEY")
  return storedData ? JSON.parse(storedData) : {}
}

class PersistentRecordSource extends RecordSource implements MutableRecordSource {
  constructor(records?: RecordMap) {
    super(records)
  }

  set(dataID: DataID, record: Record): void {
    super.set(dataID, record)
    this.saveStore()
  }

  remove(dataID: DataID): void {
    super.remove(dataID)
    this.saveStore()
  }

  clear(): void {
    super.clear()
    this.saveStore()
  }

  private saveStore(): void {
    const records = this.toJSON()
    localStorage.setItem("RELAY_STORE_KEY", JSON.stringify(records))
  }
}

function createRelayEnvironment() {
  const persistedRecords = loadRelayStore()

  const recordSource = new PersistentRecordSource(persistedRecords)

  const environment = new Environment({
    network: Network.create(executeFunction, subscribeFn),
    store: new Store(recordSource),
  })

  return environment
}

export const RelayEnvironment = createRelayEnvironment()
